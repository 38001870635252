<template>
  <div class="deposits" v-if="hasPerm('payments.view_deposit')">
    <page-header title="Dépôts" icon="fas fa-money-bill-alt" :links="getLinks()"></page-header>
    <div class="sub-header" v-show="initialLoadingDone">
      <b-row>
        <b-col cols="8">
          <h2>
            <counter-label :counter="depositsCount" label="dépot"></counter-label>
          </h2>
        </b-col>
        <b-col cols="4" class="text-right">
          <pagination
            :pages-count="pagesCount"
            :has-next="hasNext"
            :has-previous="hasPrevious"
            :selector="paginationSelector"
            :force="this.forcePage"
            @change="onPageChanged($event)"
            css-style="justify-content: flex-end;"
          >
          </pagination>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3">
          <b-form-select
            id="payment-modes"
            v-model="selectedPaymentMode"
            @change="onPaymentModeSelected"
            >
            <b-form-select-option
              :value="item"
              v-for="item in paymentModes"
              :key="item.id"
            >
              {{ item.name }}
            </b-form-select-option>
          </b-form-select>
        </b-col>
      </b-row>
    </div>
    <loading-gif :loading-name="loadingName"></loading-gif>
    <div ref="printMe">
      <div ref="excelMe">
        <div class="empty-text"  v-if="!isLoading(loadingName) && deposits.length === 0">Aucun dépôt correspondant</div>
        <table class="table table-striped" v-if="!isLoading(loadingName) && deposits.length">
          <tr>
            <th colspan="4"></th>
            <th colspan="2" style="text-align: center">Paiements</th>
            <th colspan="2" style="text-align: center">Dépenses</th>
          </tr>
          <tr>
            <th>Date</th>
            <th>N° de dépôt</th>
            <th>Commentaire</th>
            <th>Mode de paiement</th>
            <th>Montant</th>
            <th>Nb</th>
            <th>Montant</th>
            <th>Nb</th>
          </tr>
          <tr v-for="deposit in deposits" :key="deposit.id">
            <td>
              <router-link :to="{ name: 'deposit-detail', params: { depositId: deposit.id, } }">
                {{ deposit.depositOn | dateToString }}
              </router-link>
            </td>
            <td>
              {{ deposit.number | defaultValue('-') }}
            </td>
            <td>
              {{ deposit.comments }}
            </td>
            <td>
              <span v-for="paymentMode in deposit.paymentModes" :key="paymentMode.id">
                {{ paymentMode.name }}
              </span>
            </td>
            <td class="currency">
              {{ deposit.paymentsAmount | currency }}
            </td>
            <td class="number">
              {{ deposit.paymentsCount }}
            </td>
            <td class="currency">
              {{ deposit.expensesAmount | currency }}
            </td>
            <td class="number">
              {{ deposit.expensesCount }}
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import * as moment from 'moment'
import { mapActions, mapMutations } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import CounterLabel from '@/components/Controls/CounterLabel'
import LoadingGif from '@/components/Controls/LoadingGif'
import Pagination from '@/components/Controls/Pagination'
import PageHeader from '@/components/Layout/PageHeader'
import { makeDeposit, makePaymentMode } from '@/types/payments'
import { BackendApi, openDocument } from '@/utils/http'

export default {
  name: 'Deposits',
  mixins: [BackendMixin],
  components: {
    Pagination,
    CounterLabel,
    LoadingGif,
    PageHeader,
  },
  data() {
    return {
      loadingName: 'deposits',
      deposits: [],
      depositsCount: 0,
      page: 0,
      hasNext: false,
      hasPrevious: false,
      initialLoadingDone: false,
      selectedPaymentMode: makePaymentMode(),
      paymentModes: [],
      forcePage: 0,
    }
  },
  watch: {
    loading: function(newValue, oldValue) {},
  },
  computed: {
    pagesCount() {
      return Math.ceil(this.depositsCount / 100)
    },
    paginationSelector() {
      return ''
    },
  },
  mounted() {
    const mode = +(this.$route.query ? this.$route.query.mode : 0)
    this.onLoaded(mode)
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async onLoaded(mode) {
      await this.loadElements(1)
      this.paymentModes = [makePaymentMode({ name: 'Tous modes de paiement', })]
      await this.loadPaymentModes()
      let modeIndex = 0
      if (mode) {
        modeIndex = this.paymentModes.map(elt => elt.id).indexOf(mode)
        modeIndex = (modeIndex < 0) ? 0 : modeIndex
      }
      this.selectedPaymentMode = this.paymentModes[modeIndex]
    },
    onPageChanged(event) {
      this.page = event.page
      this.forcePage = 0
      this.loadElements(this.page)
    },
    onPaymentModeSelected() {
      if (this.page !== 1) {
        this.forcePage = 1
      }
      this.loadElements(this.page)
    },
    async loadElements(page) {
      this.startLoading(this.loadingName)
      let url = '/api/deposits/?page=' + (page || 1) + '&payment_mode=' + this.selectedPaymentMode.id
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.initialLoadingDone = true
        this.depositsCount = resp.data.count
        this.hasNext = !!resp.data.next
        this.hasPrevious = !!resp.data.previous
        this.deposits = resp.data.results.map(elt => makeDeposit(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    async loadPaymentModes() {
      try {
        let url = '/api/payment-modes/'
        let backendApi = new BackendApi('get', url)
        let resp = await backendApi.callApi()
        this.paymentModes = this.paymentModes.concat(
          resp.data.map(elt => makePaymentMode(elt)).filter(elt => elt.hasDeposit)
        )
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    getLinks() {
      const active = !this.isLoading(this.loadingName)
      const excelActive = active && this.deposits.length
      return [
        {
          id: 1,
          label: 'Pdf',
          callback: this.printMe,
          icon: 'fa fa-file-pdf',
          cssClass: !active ? 'btn-secondary disabled' : 'btn-secondary',
        },
        {
          id: 2,
          label: 'Excel',
          callback: this.excelMe,
          icon: 'fa fa-file-excel',
          cssClass: !excelActive ? 'btn-secondary disabled' : 'btn-secondary',
        }
      ]
    },
    async printMe() {
      const docUrl = '/documents/standard/<key>/pdf/'
      const docSlug = 'depots-' + moment().format('YYYY-MM-DD')
      const docContent = this.$refs.printMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent, 'dossier_caf')
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async excelMe() {
      const docUrl = '/documents/table-to-excel/<key>/'
      const docSlug = 'depots-' + moment().format('YYYY-MM-DD')
      const docContent = this.$refs.excelMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
  },
}
</script>

<style scoped lang="less">
</style>
